import { ChangeDetectionStrategy, Component, inject, input, ViewChild, OnInit } from '@angular/core';
import { Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { EscToastsService, FormActionsService, FormInModalComponent, FormInModalContentDirective, FormInModalHeaderDirective } from '@early-stage/components';
import { IGroup } from '../../../core/models/group.interface';
import { GroupsService } from '../../../core/services/groups.service';
import { select, Store } from '@ngxs/store';
import { LoadGroupColors } from '../../../core/store/dictionary/dictionary.actions';
import { DictionaryState } from '../../../core/store/dictionary/dictionary.state';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { FormFieldComponent } from '@early-stage/components';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'es-color-picker-modal',
    templateUrl: './color-picker-modal.component.html',
    styleUrls: ['./color-picker-modal.component.scss'],
    providers: [FormActionsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FormInModalComponent, ReactiveFormsModule, ColorSelectorComponent, FormFieldComponent, FormInModalContentDirective, FormInModalHeaderDirective],
})
export class ColorPickerModalComponent implements OnInit {
    private _store = inject(Store);
    private _toasts = inject(EscToastsService);
    private _fb = inject(FormBuilder);
    private _fa = inject(FormActionsService);
    private _groupService = inject(GroupsService);

    group = input.required<IGroup>();

    groupColors = select(DictionaryState.groupColors);

    formGroup = this._fb.group({
        color: this._fb.control<number>(null, [Validators.required]),
    });

    constructor() {
        this._fa.submitSuccess$.pipe(takeUntilDestroyed()).subscribe(() => {
            this._toasts.add({
                text: 'Kolor dziennika został zmieniony',
                type: 'success',
            });
        });
    }

    public ngOnInit(): void {
        this._fa.connect(this.formGroup);

        this.formGroup.patchValue({
            color: this.group().color.id,
        });

        this._store.dispatch(new LoadGroupColors());
    }

    public onSubmit(): void {
        this._fa.trySubmit(() => {
            return this._groupService.changeGroupColor(this.group().id, this.formGroup.getRawValue());
        });
    }
}
