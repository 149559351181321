<esc-form-in-modal (submit)="onSubmit()" [formGroup]="formGroup" [submitText]="'Zapisz'" [loading]="!groupColors()">
    <esc-form-in-modal-header>
        <h2>Zmień kolor dziennika</h2>
        <p class="text-color-grey-700">{{ group().fullName }}</p>
    </esc-form-in-modal-header>
    <esc-form-in-modal-content>
        <esc-form-field class="color-inputs">
            <es-color-selector [colors]="groupColors()" [parentFormGroup]="formGroup"></es-color-selector>
        </esc-form-field>
    </esc-form-in-modal-content>
</esc-form-in-modal>
